<template>
	<!-- Navbar start -->
	<nav class="w-screen fixed bg-opacity-90 bg-white p-4 lg:px-20 flex justify-between z-50">
		<!-- Logo start -->
		<div class="h-full flex items-center" @click="goToTop">
			<img src="@/assets/logo1.png" alt="Cakes&Bakes Logo" class="w-50 h-20 mr-2 navbar-logo" />
			<div>
				<p class="tracking-widest font-semibold">Miroslav Forrai</p>
				<p class="tracking-widest font-light">Košice</p>
			</div>
		</div>
		<!-- Logo end -->


		<!-- Toggler start -->
		<button role="menubar" class="md:hidden" @click="navToggle()">
			<i class="fa-solid fa-bars text-2xl"></i>
		</button>
		<!-- Toggler end -->

		<!-- Menu content start -->
		<div class="absolute left-0 right-0 translate-y-16 bg-opacity-90 bg-white md:bg-none md:bg-opacity-0 shadow hidden md:flex flex-col gap-4 items-center p-4 md:flex-row md:static md:shadow-none md:translate-y-0"
			role="menu" aria-expanded="false">
			<!-- Links start -->
			<a href="#services-section" @click.prevent="scrollToSection($event, '#services-section')" role="menuitem">
				<p class="mx-4">Služby</p>
			</a>

			<a href="#about-section" @click.prevent="scrollToSection($event, '#about-section')" role="menuitem">
				<p class="mx-4">O nás</p>
			</a>

			<a href="#contact-section" @click.prevent="scrollToSection($event, '#contact-section')" role="menuitem">
				<button class="btn">
					Kontakt
				</button>
			</a>
			<!-- Links end -->
		</div>
		<!-- Menu content end -->
	</nav>
	<!-- Navbar end -->
</template>


<script>
export default {
	data() {
		return {
			isMenuOpen: false,
		};
	},
	methods: {
		navToggle() {
			this.isMenuOpen = !this.isMenuOpen;
			this.updateMenuVisibility();
		},
		scrollToSection(event, sectionId) {
			event.preventDefault(); // Prevent the default anchor behavior
			const section = document.querySelector(sectionId);

			if (section) {
				section.scrollIntoView({ behavior: 'smooth' });

				// Close the menu after scrolling
				this.navToggle();
			}
		},
		goToTop() {
			// Remove the hash from the URL without changing the browser history
			history.pushState("", document.title, window.location.pathname + window.location.search);

			// Scroll to the top of the page
			window.scrollTo({
				top: 0,
				behavior: 'smooth' // Optional: define the scrolling behavior
			});
		},
		updateMenuVisibility() {
			let menu = document.querySelector("[role='menu']");
			menu.setAttribute('aria-expanded', this.isMenuOpen);
			if (this.isMenuOpen) {
				menu.classList.remove('hidden');
				menu.classList.add('flex');
				window.addEventListener('click', this.windowClick);
			} else {
				menu.classList.add('hidden');
				menu.classList.remove('flex');
				window.removeEventListener('click', this.windowClick);
			}
		},
		windowClick(event) {
			let menu = document.querySelector("[role='menu']");
			let button = document.querySelector("[role='menubar']");
			if (!menu.contains(event.target) && !button.contains(event.target)) {
				this.isMenuOpen = false;
				this.updateMenuVisibility();
			}
		},
		closeMenu() {
			this.isMenuOpen = false;
			this.updateMenuVisibility();
		}
	},
	beforeUmnount() {
		window.removeEventListener('click', this.windowClick);
	}
}
</script>
  

<style>
nav {
	z-index: 1000 !important;
}

@media (max-width: 768px) {
	.navbar-logo {
		display: none;
	}
}
</style>