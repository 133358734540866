<template>
    <!-- Services section start -->
    <section id="services-section" class="px-12 lg:px-32 py-24 relative overflow-hidden border-t border-stone-500">
        <!-- Text wrapper start -->
        <div class="flex gap-8 flex-col md:flex-row">
            <!-- Main text start -->
            <h1 class="uppercase text-5xl mb-4 font-semibold">
                SLUŽBY
            </h1>
            <!-- Main text end -->
        </div>
        <!-- Text wrapper end -->

        <!-- Services start -->
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-0 grid-rows-3 md:grid-rows-1">
            <!-- Service card start -->
            <div class="service-card mt-0 md:mt-0 shadow-lg rounded-md p-4 bg-blue-200">
                <img src="@/assets/1.jpg" alt="Domácnosti" class="mb-4 service-obrazok">
                <h1 class="uppercase text-2xl font-semibold">
                    Pranie kobercov 
                </h1>
                <p class="text-black">
                Komplexné pranie kobercov s použitím špičkovej techniky a bezpečných čistiacich prostriedkov. Efektívne odstraňuje škvrny a nečistoty, zanecháva koberce svieže a predlžuje ich životnosť.    
                </p>
            </div>
            <!-- Service card end -->

            <!-- Service card start -->
            <div class="service-card mt-4 md:mt-8 shadow-lg rounded-md p-4 bg-green-200" style="min-height: calc(100% + 1rem);">
                <img src="@/assets/4.jpg" alt="Komerčné Priestory" class="mb-4 service-obrazok">
                <h1 class="uppercase text-2xl font-semibold">
                    Odstredenie kobercov 
                </h1>
                <p class="text-black">
                    Profesionálne odstránenie nepríjemných pachov z kobercov, vrátane odolných zápachov. Zaručujeme sviežosť a hygienickú čistotu vašich kobercov.
                </p>
            </div>
            <!-- Service card end -->

            <!-- Service card start -->
            <div class="service-card mt-8 md:mt-16 shadow-lg rounded-md p-4 bg-yellow-200" style="min-height: calc(100% + 2rem);">
                <img src="@/assets/2.jpg" alt="Špeciálne Služby" class="mb-4 service-obrazok">
                <h1 class="uppercase text-2xl font-semibold">
                    Vysušenie kobercov
                </h1>
                <p class="text-black">
                    Rýchle a efektívne vysušenie kobercov po čistení. Predchádzame tvorbe plesní a zápachu, pričom udržiavame kvalitu a farbu kobercov.
                </p>
            </div>
            <!-- Service card end -->
        </div>
        <!-- Services end -->
    </section>
    <!-- Services section end -->
</template>

<script>
export default {

}
</script>

<style>
@media (max-width: 768px) {
    .service-obrazok {
        display: none;
    }
}
.service-card {
    transition: transform .2s;
}

.service-card:hover {
    transform: scale(1.05);
}

.service-card img {
    width: 100%;
    object-fit: cover;
    height: 400px;
    border-radius: 10px;
}
</style>
