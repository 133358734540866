<template>
  <section class="h-screen px-12 lg:px-32 py-32 mb-8 flex flex-row-reverse">
    <div class="w-full md:w-1/2 h-full flex flex-col md:justify-center">
      <h1 class="uppercase text-7xl xl:text-7xl mb-4 font-semibold nadpis">REVIK.SK <br>
      </h1>
      <p class=" xl:w-1/2 text-stone-500">
        Sme odborníci na pranie kobercov s mnohoročnými skúsenosťami. Kvalita
        našej práce je zaručená.
      </p>

      <p class="text-4xl xl:text-4xl mb-4 font-semibold text-stone-500" style="height: 50% !important">
        <br>
        Cena od 5 do 7 eur za m²
      </p>
    </div>
    <div
      class="absolute bottom-0 left-40 overflow-hidden h-2/5 sm:h-1/2 md:h-3/5 lg:h-4/6 -z-50 mb-20"
    >
      <img src="@/assets/image.jpg" alt="img" class="h-full pracka" />
    </div>
  </section>
</template>

<style scoped>
@media (max-width: 768px) {
  .nadpis {
    font-size: 3.5rem !important;
  }
  .pracka {
    display: none;
  }
  section {
    height: 60vh;
  }
}
</style>
