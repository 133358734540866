<template>
    <!-- About section start -->
    <section id="about-section" class="px-12 lg:px-32 py-16 relative flex items-center onas-section">

        <!-- Main text start -->
        <div class="w-2/3 pl-8">
            <h1 class="uppercase text-5xl mb-4 font-semibold ">
                O NÁS
            </h1>
            <p class="text-stone-500 mb-8 onas-text">
                Sme špecialisti na pranie kobercov s dlhoročnými skúsenosťami. Naše služby <br>
                sú dostupné pre domácnosti aj komerčné priestory. Pranie kobercov robíme <br>
                s láskou a profesionálnym prístupom. Sme z Košíc a vždy sa snažíme <br>
                poskytnúť najlepšie služby našim zákazníkom. Pre viac informácií nás neváhajte kontaktovať.
            </p>
        </div>
        <!-- Main text end -->

        <!-- Company Picture Start -->
        <div class="w-1/3">
            <img class="w-full h-auto" src="@/assets/3.jpg" alt="Forrai pranie kobercov" />
        </div>
        <!-- Company Picture End -->

    </section>
    <!-- About section end -->
</template>

<style scoped>
@media(max-width: 768px) {
    .onas-section {
        display: flex;
        flex-direction: column;
        padding: 0;
    }
    .onas-section div{
        width: 74%;
        padding: 0;
    }
    p {
        text-align: justify;
    }
    h1 {
        text-align: center;
    }
}
</style>
