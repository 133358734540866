<template>
    <!-- Footer start -->
    <div class="bg-stone-700 w-full flex justify-center items-center md:justify-evenly flex-col md:flex-row gap-8 p-8">

        <!-- Overview start -->
        <div class="text-stone-100 flex items-center justify-center md:justify-start text-center md:text-left">
			<img src="@/assets/MH.png" alt="Marek Horváth" class="w-10 h-10 md:w-16 md:h-16 mr-2">
            <div>
                <p class="tracking-widest font-semibold text-stone-100">&copy; 2024 Marek Horváth. All rights reserved.</p>
                <!--p class="tracking-widest font-light text-stone-100">PHOTOGRAPHY</p-->
            </div>
        </div>
        <!-- Overview end -->

        <!-- Links start -->
        <div class="text-stone-100 flex justify-center items-center gap-8">
			<a href="https://www.linkedin.com/in/horvathmar/">
				<i class="fa-brands fa-linkedin socialIcon"></i>
			</a>
            <!--a href="#">
				<i class="fa-brands fa-instagram socialIcon"></i>
			</a>
			<a href="#">
				<i class="fa-brands fa-facebook socialIcon"></i>
			</a>
			<a href="#">
				<i class="fa-brands fa-dribbble socialIcon"></i>
			</a>
			<a href="#">
				<i class="fa-brands fa-pinterest socialIcon"></i>
			</a-->
        </div>
        <!-- Links end -->
    </div>
    <!-- Footer end -->
</template>

<script>
export default {

}
</script>

<style>

</style>
