<template>

    <section id="contact-section" class="px-12 lg:px-32 py-32 relative text-center">
        <h1 class="uppercase text-5xl mb-12 font-semibold">
            Kontakt
        </h1>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 items-center">
            <div class="w-full h-[500px] mx-auto">
                <l-map
                class="w-full h-full"
                :zoom="14"
                :center="center"
                >
                <l-tile-layer 
                    :url="url"
                    :attribution="attribution"
                ></l-tile-layer>
                <l-marker :lat-lng="center"></l-marker>
                </l-map>
            </div>

            <!-- Info start -->
            <div class="flex flex-col w-full items-center justify-center text-stone-500">
                <h2 class="text-2xl mb-4">Revik</h2>
                <p class="mb-4">Miroslav Forrai</p>
                <p class="mb-4">Pranie kobercov v Košiciach</p>
                <p class="mb-4">Adresa: Pod horou 23, 040 16 Košice</p>
                <p class="mb-4">Telefón: 0903 485 311</p>
            </div>

            <div class="w-full h-[500px] mx-auto">
                <img src="@/assets/dom1.png" alt="Image Description" class="w-full h-full object-cover">
            </div>
        </div>
    </section>
</template>


<script>
import { LMap, LTileLayer, LMarker } from "vue3-leaflet";
import "leaflet/dist/leaflet.css";

export default {
    components: {
        LMap,
        LTileLayer,
        LMarker
    },
    data() {
        return {
            center: [48.709010, 21.204130],  
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
        };
    },
}
</script>
  
<style>
/* You may need to adjust the z-index of the map */
#map {
    width: 100%;
    height: 100%;
}
</style>
